import React, { ReactEventHandler } from "react";
import { handleEnterKeyPress } from "libs/utils";
import "./EditionCover.css";

interface Props {
  date: string;
  handleSelect: ReactEventHandler;
}

export function EditionCover(props: Props): JSX.Element {
  const { date, handleSelect } = props;
  const day = new Date(date);
  const dayDisplay = day.toUTCString();
  return (
    <button
      className="edition_cover"
      onClick={handleSelect}
      onKeyDown={handleEnterKeyPress(handleSelect)}
    >
      {/* <h1 className="edition_cover__title">Edition title</h1> */}
      <figure className="edition_cover__thumb_wrapper">
        <span className="edition_cover__thumb" />
      </figure>
      <time className="edition_cover__date" dateTime={dayDisplay}>
        {dayDisplay}
      </time>
    </button>
  );
}
