import React, { MouseEventHandler } from "react";
import { WingBlank } from "antd-mobile";
import { locale } from "locale/locale";
import { ImageElement } from "components/ImageElement/ImageElement";
import { AuthorsElement } from "components/AuthorsElement/AuthorsElement";
import { ArticleType } from "reducers/articleReducer";
import "./Card.css";

interface CardInterface extends ArticleType {
  handleClick: MouseEventHandler;
}

export function Card(props: CardInterface): JSX.Element {
  const {
    authors,
    title,
    id,
    premium,
    leadAsset,
    contentType,
    handleClick
  } = props;

  const { url: imageUrl, alt } = leadAsset;

  return (
    <WingBlank key={id} size="md">
      <div
        className={`card ${
          premium ? "card--premium" : ""
        } card--${contentType}`}
        onClick={handleClick}
        role="presentation"
      >
        {premium && (
          <div className="card_premium">
            <p className="card_premium_text">{locale.PREMIUM}</p>
          </div>
        )}
        <h2 className="card_headline">{title}</h2>
        {authors && <AuthorsElement authors={authors} />}
        <div className="card_share">
          <button className="socialBtn socialBtn--small socialBtn_follow">
            Follow
          </button>
          <button className="socialBtn socialBtn--small socialBtn_save">
            Save
          </button>
        </div>
        {imageUrl && (
          <ImageElement src={imageUrl} altText={alt} isFullWidth={true} />
        )}
      </div>
    </WingBlank>
  );
}
