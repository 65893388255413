import React from "react";
import { ImageElement } from "components/ImageElement/ImageElement";
import { VideoElement } from "components/VideoElement/VideoElement";
import { EmbedElement } from "components/EmbedElement/EmbedElement";
import { BodyItem } from "services/content/contentProxy";

interface PropsInterface {
  content: BodyItem;
}

export function ArticleContentBlock(props: PropsInterface): JSX.Element | null {
  const { content: contentBlock } = props;

  switch (contentBlock.type) {
    case "image":
      return (
        <ImageElement
          altText={contentBlock.alt}
          caption={contentBlock.alt}
          src={contentBlock.src || ""}
          isFullWidth={true}
        />
      );

    case "text":
      return <p> {contentBlock.text}</p>;

    // case "video":
    //   const rendition = contentBlock.renditions.find((rendition): boolean => {
    //     return rendition.url !== undefined;
    //   });

    //   return (
    //     <VideoElement
    //       isFullWidth={true}
    //       poster={rendition ? rendition.url : ""}
    //       videoId={contentBlock.videoEmbedId}
    //     />
    //   );

    // case "tweet":
    //   return (
    //     <EmbedElement
    //       url={`https://twitter.com/x/status/${contentBlock.tweetId}`}
    //     />
    //   );
  }

  return null;
}
