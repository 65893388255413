import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ImageElement } from "components/ImageElement/ImageElement";
import { DateElement } from "components/DateElement/DateElement";
import { AuthorsElement } from "components/AuthorsElement/AuthorsElement";
import { ArticleContentBlock } from "components/ArticleContentBlock/ArticleContentBlock";
import { cleanPunctuation } from "libs/utils";
import { fetchArticle } from "actions/ArticleActions";
import { ArticleType } from "reducers/articleReducer";
import { StateInterface } from "reducers/rootReducer";
import { Skeleton } from "components/Skeleton/Skeleton";
import "./Article.css";

interface PropsInterface {
  id: string;
}

interface DispatchProps {
  fetchArticle: typeof fetchArticle;
}

class ArticleView extends PureComponent<
  PropsInterface & MapStatePropsInterface & DispatchProps
> {
  public componentDidMount(): void {
    const {
      articleData: { url, preview },
      id,
      fetchArticle,
      isOffline
    } = this.props;

    if (preview) {
      fetchArticle(id, url, isOffline);
    }
  }

  public render(): JSX.Element {
    const {
      title,
      authors,
      body = [],
      leadAsset,
      datePublished
    } = this.props.articleData;
    const { url: imageUrl, alt } = leadAsset;

    const cleanedHeadline = cleanPunctuation(title);

    const bodyContent =
      body.length === 0 ? (
        <Skeleton count={5} isAnimated />
      ) : (
        body.map(
          (content, i: number): JSX.Element => (
            <ArticleContentBlock content={content} key={`el-${i}`} />
          )
        )
      );

    return (
      <article className="article__wrapper">
        <h1 className="article__headline">{cleanedHeadline}</h1>
        {imageUrl && (
          <ImageElement isFullWidth={true} src={imageUrl} altText={alt} />
        )}
        {authors && <AuthorsElement authors={authors} />}
        {datePublished && <DateElement date={datePublished} />}
        {bodyContent}
      </article>
    );
  }
}

interface MapStatePropsInterface {
  isOffline: boolean;
  articleData: ArticleType;
}

function mapStateToProps(
  state: StateInterface,
  ownProps: PropsInterface
): MapStatePropsInterface {
  const { isOffline } = state.config;
  const { id } = ownProps;
  const articleData = state.articles[id];

  return {
    articleData,
    isOffline
  };
}

export default connect(
  mapStateToProps,
  { fetchArticle }
)(ArticleView);
