import React from "react";
import { NavBar } from "antd-mobile";
import "./Header.css";

interface Props {
  showEditionInfo: boolean;
  showButton?: boolean;
  menuAction: () => void;
  editionId: string | null;
  title?: string;
}

export function Header(props: Props): JSX.Element {
  const {
    title,
    showButton = false,
    editionId,
    showEditionInfo,
    menuAction
  } = props;

  return (
    <NavBar
      className="header"
      mode="dark"
      rightContent={showButton && <button onClick={menuAction}>Menu</button>}
    >
      <h1 className="header__title">{title}</h1>
      {showEditionInfo && <p className="header__edition">{editionId}</p>}
    </NavBar>
  );
}
