import React from "react";
//@ts-ignore
import tinytime from "tinytime";

interface PropsInterface {
  date: string;
}

const dateTemplate = tinytime(`{Do} {MMMM} {YYYY}, {H}:{mm}`);

export function DateElement(props: PropsInterface): JSX.Element {
  const { date } = props;
  const dateText: string = dateTemplate.render(new Date(date));

  return (
    <time className="article__date" dateTime={date}>
      {dateText}
    </time>
  );
}
