import React, { PureComponent, ReactElement } from "react";
import { SettingsView } from "views/SettingsView/SettingsView";
import ActivityView from "views/ActivityView/ActivityView";
import NewsStandView from "views/NewsStandView/NewsStandView";
import SectionList from "components/SectionList/SectionList";
import { FiBook, FiRss, FiUser, FiSettings } from "react-icons/fi";
import { TabBar } from "antd-mobile";
import { Areas, AreasTypes } from "reducers/configReducer";
import { ArticleType } from "reducers/articleReducer";

interface PropsInterface {
  area: AreasTypes;
  activeEdition: null | string;
  activeSection: null | string;
}

interface TabAreaType {
  icon: ReactElement;
  key: AreasTypes | string;
  title: string;
  content: ReactElement;
  selected: boolean;
}

export class GlobalNavigation extends PureComponent<PropsInterface> {
  private handleClick = (area: AreasTypes | string): void => {
    history.pushState(null, "", `/${area}`);
  };

  private readonly _TINT_COLOUR = "#f00";

  private readonly tabAreas = (): TabAreaType[] => {
    const { area, activeEdition, activeSection } = this.props;
    const { ACTIVITY, SETTINGS, EDITION } = Areas;

    return [
      {
        icon: <FiBook />,
        key: EDITION,
        title: "Edition",
        content: activeEdition ? (
          <SectionList
            activeSection={activeSection}
            activeEdition={activeEdition}
          />
        ) : (
          <NewsStandView />
        ),
        selected: area === EDITION && activeEdition !== "LIVE_EDITION"
      },
      {
        icon: <FiRss />,
        key: "EDITION/LIVE_EDITION/news-lv0",
        title: "Live",
        content: (
          <SectionList
            activeSection={activeSection}
            activeEdition={activeEdition}
          />
        ),
        selected: area === EDITION && activeEdition === "LIVE_EDITION"
      },
      {
        icon: <FiUser />,
        key: ACTIVITY,
        title: "Activity",
        content: <ActivityView />,
        selected: area === ACTIVITY
      },
      {
        icon: <FiSettings />,
        key: SETTINGS,
        title: "Settings",
        content: <SettingsView />,
        selected: area === SETTINGS
      }
    ];
  };

  public render(): JSX.Element {
    return (
      <TabBar
        hidden={false}
        tintColor={this._TINT_COLOUR}
        prerenderingSiblingsNumber={0}
      >
        {this.tabAreas().map(
          (tabArea): JSX.Element => (
            <TabBar.Item
              icon={tabArea.icon}
              key={tabArea.key}
              onPress={(): void => this.handleClick(tabArea.key)}
              selected={tabArea.selected}
              selectedIcon={tabArea.icon}
              title={tabArea.title}
            >
              {tabArea.content}
            </TabBar.Item>
          )
        )}
      </TabBar>
    );
  }
}
