import React from "react";
import { ArticleType } from "reducers/articleReducer";

export function AuthorsElement(
  props: Pick<ArticleType, "authors">
): JSX.Element | null {
  const { authors } = props;
  const validAuthors = authors.filter(({ name }): boolean => name !== null);
  if (validAuthors.length < 1) return null;

  return (
    <p className="card_authors">
      By{" "}
      {validAuthors
        .map(({ name, title }): string => {
          let fullName = "";
          if (name) fullName += name;
          return title ? (fullName += `, ${title}`) : fullName;
        })
        .join(" and ")}
    </p>
  );
}
